<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryData.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="openAuditList">待审核列表</el-button>
          <el-button type="primary" @click="openRadioTypeList">电台分类</el-button>
          <el-button type="primary" @click="toAdd">添加电台</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="radioList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          label="电台名"
          prop="title"
          align="center"
        ></el-table-column>

        <el-table-column
          label="电台描述"
          prop="desc"
          align="center"
        ></el-table-column>

        <el-table-column
            label="缩略图"
            prop="thumb_url"
            align="center"
            #default="scope"
          >
          <div class="radio_thumb_img">
            <img :src="scope.row.thumb_url" alt="" srcset="">
          </div>
        </el-table-column>

        <el-table-column
            label="状态"
            prop="is_enable"
            align="center"
          >
          <template slot-scope="scope">
              <el-tag
                type="success"
                v-if="scope.row.is_enable == 1"
              >
                显示
              </el-tag>
              <el-tag type="danger" v-else
                >隐藏
              </el-tag>
            </template>
        </el-table-column>

        <el-table-column
            label="创建人"
            align="center"
          >
          <template slot-scope="scope">
              <div class="user_box">
                <div class="user_img">
                  <img :src="scope.row.creator.avatar" alt="" srcset="">
                </div>
                <div class="user_name">
                  {{scope.row.creator.nickname}}
                </div>
                <div class="user_role">
                  {{`(${scope.row.creator.role})`}}
                </div>
              </div>
          </template>
        </el-table-column>
         

        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.create_time !== null">
              {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>


        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editColumn(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-tickets"
              circle
              @click="toOpenSection(scope.row.id)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="toRemoveRadio(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

         <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleRadioSizeChange"
        @current-change="handleRadioCurrentChange"
        :current-page="queryData.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryData.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="radioTotal"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加电台 -->
      <el-dialog
        :title="addColumnTitle"
        :center="true"
        :visible.sync="addColumnDialog"
        width="30%"
        @close="addColumnClose"
      >
        <el-form :model="addColumnForm">

          <el-form-item label="电台分类:" label-width="100px">
            <el-select
              v-model="addColumnForm.type_id"
              placeholder="请选择电台分类"
              clearable
            >
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="item in radioTypeList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电台名:" label-width="100px">
            <el-input
              v-model="addColumnForm.title"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述:" label-width="100px">
            <el-input
              v-model="addColumnForm.desc"
               type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>

          <el-form-item label="缩略图:" label-width="100px">
             <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeRadioThumbUpload"
              
            >
              <img
                v-if="radioThumbSrc"
                style="width: 180px;height:100%"
                :src="radioThumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="是否显示:" label-width="100px">
            <el-select
              v-model="addColumnForm.is_enable"
              placeholder="请选择是否显示"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in enableArr"
                :key="item.key"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label-width="40%">
            <el-button
              type="primary"
              v-if="addColumnTitle == '添加电台'"
              @click="addColumnNow"
              >立即添加</el-button
            >
            <el-button type="primary" v-else @click="editColumnNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 电台小节列表弹框 -->
      <el-dialog
        title="电台小节"
        :visible.sync="radioSectionDialog"
        :center="true"
        width="80%"
        :close-on-click-modal="false"
        @close="radioSectionClose"
      >
        <div class="title_box">
          <div></div>
          <div class="hearingLibDialog_title">
            <el-button type="primary" @click="toAddRadioLab"
              >添加电台小节</el-button
            >
          </div>
        </div>

        <el-table
          :data="radioLibList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="标题"
            prop="title"
            align="center"
          ></el-table-column>

          <el-table-column
            label="缩略图"
            prop="thumb_url"
            align="center"
            #default="scope"
          >
          <div class="thumb_img">
            <img :src="scope.row.thumb_url" alt="" srcset="">
          </div>
          </el-table-column>
          <el-table-column
            label="时长"
            prop="duration"
            align="center"
            #default="scope"
          >
            {{ scope.row.duration }}s
          </el-table-column>

          <el-table-column prop="orderby" label="排序" align="center">
          </el-table-column>

          
          <el-table-column label="审核状态" align="center" prop="display">
            <template slot-scope="scope">
             <div @click="editradioLibAudit(scope.row.id,'radioLibList')">
                <el-tag
                  type="danger"
                  v-if="scope.row.is_enable == 0"
                >
                  审核未通过
                </el-tag>
                <el-tag type="success" v-else-if="scope.row.is_enable==1"
                  >审核通过
                </el-tag>
                <el-tag type="info" v-else-if="scope.row.is_enable==2"
                  >草稿箱
                </el-tag>
                 <el-tag type="warning" v-else
                  >审核中
                </el-tag>
             </div>
            </template>
          </el-table-column>

          <el-table-column
              label="审核人"
              align="center"
            >
            <template slot-scope="scope">
                <div class="user_box" v-if="!Array.isArray(scope.row.rbacUserChecker)">
                  <div class="user_img">
                    <img :src="scope.row.rbacUserChecker.avatar" alt="" srcset="">
                  </div>
                  <div class="user_name">
                    {{scope.row.rbacUserChecker.nickname}}
                  </div>
                </div>
            </template>
          </el-table-column>

          <el-table-column
              label="创建人"
              align="center"
            >
            <template slot-scope="scope">
                <div class="user_box">
                  <div class="user_img">
                    <img :src="scope.row.creator.avatar" alt="" srcset="">
                  </div>
                  <div class="user_name">
                    {{scope.row.creator.nickname}}
                  </div>
                  <div class="user_role">
                    {{`(${scope.row.creator.role})`}}
                  </div>
                </div>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.create_time !== null">
                {{
                  $moment(scope.row.create_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
              <div v-else>空</div>
            </template>
          </el-table-column>


          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="warning"
                icon="el-icon-sort"
                size="small"
                circle
                @click="toSet(scope.row)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editRadioLab(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeRadioLab(scope.row.id,'lab')"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

           <!-- 分页区域 -->
        <div class="pagination">
          <span></span>
           <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="queryLabForm.page"
           :page-sizes="[3, 5, 8, 10]"
           :page-size="queryLabForm.limit"
           layout="total, sizes, prev, pager, next, jumper"
           :total="total"
           algin:="center"
         ></el-pagination>
        </div>
      </el-dialog>

      <!-- 电台小节待审核弹框 -->
      <el-dialog
        title="待审核列表"
        :visible.sync="auditListDialog"
        :center="true"
        width="80%"
        :close-on-click-modal="false"
      >
        <el-table
          :data="radioLibAuditList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="标题"
            prop="title"
            align="center"
          ></el-table-column>

          <el-table-column
            label="缩略图"
            prop="thumb_url"
            align="center"
            #default="scope"
          >
          <div class="thumb_img">
            <img :src="scope.row.thumb_url" alt="" srcset="">
          </div>
          </el-table-column>

          <el-table-column
              label="创建人"
              align="center"
            >
            <template slot-scope="scope">
                <div class="user_box">
                  <div class="user_img">
                    <img :src="scope.row.creator.avatar" alt="" srcset="">
                  </div>
                  <div class="user_name">
                    {{scope.row.creator.nickname}}
                  </div>
                  <div class="user_role">
                    {{`(${scope.row.creator.role})`}}
                  </div>
                </div>
            </template>
          </el-table-column>

          <el-table-column
            label="当前状态"
            prop="result"
            align="center"
          ></el-table-column>
  
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.create_time !== null">
                {{
                  $moment(scope.row.create_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
              <div v-else>空</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editradioLibAudit(scope.row.id,'radioLibAudit')"
                size="small"
              ></el-button>
               <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeRadioLab(scope.row.id,'audit')"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

           <!-- 分页区域 -->
        <div class="pagination">
          <span></span>
           <el-pagination
           @size-change="handleRadioLibAuditSizeChange"
           @current-change="handleRadioLibAuditCurrentChange"
           :current-page="radioLibAuditFrom.page"
           :page-sizes="[3, 5, 8, 10]"
           :page-size="radioLibAuditFrom.limit"
           layout="total, sizes, prev, pager, next, jumper"
           :total="radioLibAuditTotal"
           algin:="center"
         ></el-pagination>
        </div>
      </el-dialog>


     <!-- 编辑电台小节待审核状态弹框 -->
      <el-dialog
        title="电台小节审核"
        :center="true"
        :visible.sync="auditDialog"
        width="40%"
        @close="radioAuditClose"
        :close-on-click-modal="false"
      >
        <el-form :model="editAuditFrom">
          <el-form-item el-form-item label="审核状态:" label-width="100px">
            <el-select
              v-model="editAuditFrom.enable"
              placeholder="请选择审核状态"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in enableAuditArr"
                :key="item.key"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item el-form-item label="审核失败原因:" label-width="100px">
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请输入审核失败原因"
                v-model="editAuditFrom.result"
              />
          </el-form-item>


          <el-form-item label-width="40%">
            <el-button type="primary" @click="editRadioAuditNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 添加电台小节弹框 -->
      <el-dialog
        :title="radioLabTitle"
        :visible.sync="radioLabDialog"
        @close="radioLabClosed"
        :center="true"
        width="50%"
        :close-on-click-modal="false"
      >
        <el-form :model="radioLabForm" ref="addForm">

          <el-form-item  v-if="isRadioLab != 'add'" el-form-item label="电台分类：" label-width="100px">
            <el-select
              v-model="radioLabForm.radio_id"
              placeholder="请选择等级"
              clearable
            >
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="item in radioList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item el-form-item label="标题：" label-width="100px">
            <el-input
              v-model="radioLabForm.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>

          <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
            >
              <img
                v-if="thumbSrc"
                style="width: 100%;height:100%"
                :src="thumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="isRadioLab == 'add'"
            el-form-item
            label="音频："
            label-width="100px"
          >
            <div @click="addAudio" v-if="audioSrc">
              <audio
                :src="audioSrc"
                ref="audio"
                autoplay="autoplay"
                controls="controls"
                style="outline: none"
              ></audio>
            </div>
            <el-button v-else type="primary" @click="addAudio"
              >上传音频</el-button
            ><span>{{ File_name }}</span>
            <input
              type="file"
              accept="audio/*"
              hidden
              ref="file"
              @change="selectAudio"
            />
            <el-progress :percentage="progress" v-if="progress"></el-progress>
          </el-form-item>

          <el-form-item v-else el-form-item label="音频：" label-width="100px">
            <div>
              <audio
                :src="audioSrc"
                ref="audio"
                autoplay="autoplay"
                controls="controls"
                style="outline: none"
              ></audio>
            </div>
          </el-form-item>

          <el-form-item el-form-item label="字幕：" label-width="100px">
            <quill-editor
              v-model="radioLabForm.subtitle"
              ref="myQuillEditor"
            >
            </quill-editor>
          </el-form-item>
         
          <el-form-item label-width="40%" style="margin-top: 20px">
            <el-button
              type="primary"
              v-if="isRadioLab == 'add'"
              @click="addRadioLabNow"
              >立即添加</el-button
            >
            <el-button type="primary" v-else @click="editRadioLabNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>


      <!-- 电台分类列表弹框 -->
      <el-dialog
        title="电台分类列表"
        :visible.sync="RadioTypeDialog"
        :center="true"
        width="80%"
        :close-on-click-modal="false"
      >
        <div class="title_box">
          <div></div>
          <div class="hearingLibDialog_title">
            <el-button type="primary" @click="addRadioType"
              >添加电台分类</el-button
            >
          </div>
        </div>

        <el-table
          :data="radioTypeList"
          stripe
          class="table"
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="标题"
            prop="title"
            align="center"
          ></el-table-column>

          <el-table-column
            label="缩略图"
            prop="thumb_url"
            align="center"
            #default="scope"
          >
          <div class="thumb_img">
            <img :src="scope.row.thumb_url" alt="" style="width:80px;height:80px" srcset="">
          </div>
          </el-table-column>

          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.create_time !== null">
                {{
                  $moment(scope.row.create_time * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </div>
              <div v-else>空</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="editRadioType(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeRadioType(scope.row.id)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!-- 添加电台分类弹框 -->
      <el-dialog
        :title="addRadioTypeTitle"
        :center="true"
        :visible.sync="addRadioTypeDialog"
        width="40%"
        @close="addRadioTypeClose"
        :close-on-click-modal="false"
      >
        <el-form :model="addRadioTypeForm">
          <el-form-item label="电台分类名:" label-width="100px">
            <el-input
              v-model="addRadioTypeForm.title"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="分类缩略图:" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeRadioTypeThumbUpload"
            >
              <img
                v-if="addRadioTypeThumbSrc"
                style="width: 180rpx;height:100%"
                :src="addRadioTypeThumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item label-width="40%">
            <el-button
              type="primary"
              v-if="isAddRadioType"
              @click="addRadioTypeNow"
              >立即添加</el-button
            >
            <el-button type="primary" v-else @click="editRadioTypeNow"
              >立即修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>


       <!-- 排序弹框 -->
    <el-dialog
      title="排序"
      :visible.sync="setDialog"
      width="30%">
       <el-form :model="setForm" ref="setForm" :rules="rules">
            <el-form-item label="排序值:" label-width="100px" prop="orderby">
              <el-input placeholder="请输入排序值" v-model.number="setForm.orderby" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDialog = false">取 消</el-button>
        <el-button type="primary" @click="setNow">确 定</el-button>
      </span>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
var COS = require("cos-js-sdk-v5");
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
  RadioList,
  RadioAdd,
  getRadioCosToken,
  RadioEdit,
  RadioDel,
  RadioLibList,
  RadioLibAdd,
  RadioLibEdit,
  RadioLibDel,
  RadioLibEnable,
  RadioTypeList,
  RadioTypeAdd,
  RadioTypeEdit,
  RadioTypeDel,
  RadioLibAuditList,
  RadioLibAuditEnable,
  RadioLibOrder,
} from "@/api/Korean/korean_radio_cateList.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      // 电台列表
      columnDialog: false,
      addColumnDialog: false,
      addColumnTitle: "添加电台",
      radioList: [],
      queryData: {
        title: null,
        start_time: null,
        end_time: null,
        page: 1,
        limit: 10,
      },
      radioTotal: 0,

      times: null,

      addRadioForm: {
        cate_id: null,
        title: "",
        thumb: "",
        desc: "",
      },
      radioSectionDialog: false,
      radioLibList: [],
      isRadioLab: "add",
      radioLabTitle: "添加电台小节",
      radioLabDialog: false,
      File_name: "",
      progress: 0,
      audioSrc: "",
      radioLabForm: {
        radio_id: null,
        thumb: "",
        title: "",
        audio: "",
        subtitle: "",
      },
      thumbSrc: "",
      radioLabId: null,

      addColumnForm: {
        type_id: null,
        title: "",
        desc: "",
        thumb: "",
        is_enable: null,
      },
      radioThumbSrc: "",
      queryLabForm: {
        radio_id: null,
        page: 1,
        limit: 10,
      },
      total: 0,

      RadioTypeDialog: false,
      radioTypeList: [],
      addRadioTypeForm: {
        title: "",
        thumb: "",
      },
      isAddRadioType: true,
      addRadioTypeTitle: "",
      addRadioTypeDialog: false,
      addRadioTypeThumbSrc: "",
      enableArr: [
        { key: 0, value: "隐藏" },
        { key: 1, value: "显示" },
      ],

      // 审核变量
      auditListDialog: false,
      radioLibAuditTotal: 0,
      radioLibAuditList: [],
      radioLibAuditFrom: {
        page: 1,
        limit: 10,
      },

      auditDialog: false,
      auditTarget: "",
      editAuditFrom: {
        lib_id: "",
        enable: "",
        result: "",
      },

      enableAuditArr: [
        { key: 0, value: "审核未通过" },
        { key: 1, value: "审核通过" },
      ],
      rules: {
        orderby: [
          {
            required: true,
            message: "排序值不能为空",
            trigger: "blur",
          },
          { type: "number", message: "排序值必须为数字值" },
        ],
      },
      setForm: {
        lib_id: null,
        orderby: null,
      },
      setDialog: false,
    };
  },

  created() {
    this.getRadioList();
    this.getRadioTypeList();
  },
  methods: {
    // 点击排序图标
    toSet(row) {
      this.setForm.lib_id = row.id;
      this.setForm.orderby = row.orderby;
      this.setDialog = true;
    },

    // 点击立即设置排序
    setNow() {
      this.$refs.setForm.validate((valid) => {
        if (valid) {
          RadioLibOrder(this.setForm).then((res) => {
            if (res.code !== 1) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success("设置排序成功~");
            this.setDialog = false;
            this.getRadioLibList(this.radioLabId);
          });
        } else {
          return false;
        }
      });
    },

    // 打开待审核列表
    openAuditList() {
      this.auditListDialog = true;
      this.getRadioLibAuditList();
    },

    // 获取电台小节待审核列表
    getRadioLibAuditList() {
      RadioLibAuditList(this.radioLibAuditFrom).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.radioLibAuditTotal = res.data.total;
        this.radioLibAuditList = res.data.data;
      });
    },

    // 打开编辑日语APP电台小节审核弹框
    editradioLibAudit(id, target) {
      this.editAuditFrom.lib_id = id;
      this.auditTarget = target;
      this.auditDialog = true;
    },
    // 点击立即编辑日语APP电台小节审核状态
    editRadioAuditNow() {
      if (
        this.editAuditFrom.enable === "" ||
        this.editAuditFrom.enable === null
      ) {
        this.$message.error("请选择审核状态");
        return;
      }
      RadioLibAuditEnable(this.editAuditFrom).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改审核状态成功");
        this.auditDialog = false;
        if (this.auditTarget == "radioLibList") {
          this.getRadioLibList(this.radioLabId);
        } else {
          this.getRadioLibAuditList();
        }
      });
    },

    // 编辑日语APP电台小节审核弹框关闭事件
    radioAuditClose() {
      this.editAuditFrom = {
        lib_id: "",
        enable: "",
        result: "",
      };
    },

    // 打开电台分类弹框
    openRadioTypeList() {
      this.RadioTypeDialog = true;
      // this.getRadioTypeList();
    },

    // 获取电台分类列表
    getRadioTypeList() {
      RadioTypeList().then((res) => {
        this.radioTypeList = res.data;
      });
    },

    // 点击添加分类按钮
    addRadioType() {
      this.isAddRadioType = true;
      this.addRadioTypeTitle = "添加电台分类";
      this.addRadioTypeDialog = true;
    },

    // 添加电台弹框关闭
    addRadioTypeClose() {
      this.addRadioTypeForm = {
        title: "",
        thumb: "",
      };
      this.addRadioTypeThumbSrc = "";
    },

    // 上传电台分类缩略图
    beforeRadioTypeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getRadioCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addRadioTypeForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.addRadioTypeThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击立即添加电台分类
    addRadioTypeNow() {
      if (this.addRadioTypeForm.title == "") {
        this.$message.error("请输入电台分类名");
        return;
      } else if (this.addRadioTypeForm.thumb == "") {
        this.$message.error("请上传电台分类缩略图");
        return;
      }
      RadioTypeAdd(this.addRadioTypeForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }

        this.$message.success("添加电台分类成功");
        this.addRadioTypeDialog = false;
        this.getRadioTypeList();
      });
    },

    // 点击编辑电台分类图标
    editRadioType(row) {
      this.addRadioTypeTitle = "编辑电台分类";
      this.isAddRadioType = false;
      this.addRadioTypeDialog = true;
      this.addRadioTypeForm = JSON.parse(JSON.stringify(row));
      this.addRadioTypeThumbSrc = this.addRadioTypeForm.thumb_url;
    },

    // 点击立即编辑电台分类
    editRadioTypeNow() {
      if (this.addRadioTypeForm.title == "") {
        this.$message.error("请输入电台分类名");
        return;
      } else if (this.addRadioTypeForm.thumb == "") {
        this.$message.error("请上传电台分类缩略图");
        return;
      }
      RadioTypeEdit(this.addRadioTypeForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑电台分类成功");
        this.addRadioTypeDialog = false;
        this.getRadioTypeList();
      });
    },

    // 删除电台分类
    async removeRadioType(id) {
      const result = await this.$confirm("是否要删除该电台分类?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      RadioTypeDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除电台分类成功");
        this.getRadioTypeList();
      });
    },

    // 获取电台列表数据
    getRadioList() {
      RadioList(this.queryData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.radioTotal = res.data.total;
        this.radioList = res.data.data;
      });
    },

    // 添加电台弹窗
    toAdd() {
      this.isAdd = "add";
      this.addColumnTitle = "添加电台";
      this.addColumnDialog = true;
    },

    // 上传电台缩略图
    beforeRadioThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getRadioCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addColumnForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.radioThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击立即添加电台
    addColumnNow() {
      if (
        this.addColumnForm.type_id == "" ||
        this.addColumnForm.type_id == null
      ) {
        this.$message.error("请选择电台分类");
        return;
      } else if (this.addColumnForm.title == "") {
        this.$message.error("请输入添加的电台名称");
        return;
      } else if (this.addColumnForm.desc == "") {
        this.$message.error("请输入电台描述");
        return;
      } else if (this.addColumnForm.thumb == "") {
        this.$message.error("请上传电台缩略图");
        return;
      } else if (
        this.addColumnForm.is_enable === "" ||
        this.addColumnForm.is_enable == null
      ) {
        this.$message.error("请选择是否显示");
        return;
      }
      RadioAdd(this.addColumnForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加电台成功");
        this.addColumnDialog = false;
        this.getRadioList();
      });
    },

    // 点击修改电台图标,打开弹框
    editColumn(item) {
      this.addColumnForm = JSON.parse(JSON.stringify(item));
      this.radioThumbSrc = this.addColumnForm.thumb_url;
      this.addColumnTitle = "编辑电台";
      this.addColumnDialog = true;
    },

    // 点击立即修改电台
    editColumnNow() {
      if (
        this.addColumnForm.type_id == "" ||
        this.addColumnForm.type_id == null
      ) {
        this.$message.error("请选择电台分类");
        return;
      } else if (this.addColumnForm.title == "") {
        this.$message.error("请输入添加的电台名称");
        return;
      } else if (this.addColumnForm.desc == "") {
        this.$message.error("请输入电台描述");
        return;
      } else if (this.addColumnForm.thumb == "") {
        this.$message.error("请上传电台缩略图");
        return;
      } else if (
        this.addColumnForm.is_enable === "" ||
        this.addColumnForm.is_enable == null
      ) {
        this.$message.error("请选择是否显示");
        return;
      }
      RadioEdit(this.addColumnForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改电台成功");
        this.addColumnDialog = false;
        this.getRadioList();
      });
    },

    // 删除电台
    async toRemoveRadio(id) {
      const result = await this.$confirm("是否要删除该电台?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      RadioDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除电台成功");
        this.getRadioList();
      });
    },

    //  查询
    toQuery() {
      if (this.times) {
        this.queryData.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryData.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryData.start_time = null;
        this.queryData.end_time = null;
      }
      this.getRadioList();
    },

    // 添加电台弹框关闭
    addColumnClose() {
      this.addColumnForm = {
        type_id: null,
        title: "",
        desc: "",
        thumb: "",
        is_enable: null,
      };
      this.radioThumbSrc = "";
    },

    // 打开电台小节弹框
    toOpenSection(id) {
      this.radioLabId = id;
      this.getRadioLibList(id);
      this.radioSectionDialog = true;
    },

    // 获取听力小节数据
    getRadioLibList(id) {
      this.queryLabForm.radio_id = id;
      RadioLibList(this.queryLabForm).then((res) => {
        this.total = res.data.total;
        this.radioLibList = res.data.data;
      });
    },
    // 电台小节列表弹框关闭
    radioSectionClose() {
      this.queryLabForm = {
        radio_id: null,
        page: 1,
        limit: 10,
      };
      this.total = 0;
    },

    // 添加电台小节
    toAddRadioLab() {
      this.isRadioLab = "add";
      this.radioLabTitle = "添加电台小节";
      this.radioLabDialog = true;
    },

    // 点击上传音频
    addAudio() {
      this.$refs.file.click();
    },
    // 选中音频
    selectAudio(e) {
      let file = this.$refs.file.files[0];
      this.File_name = file.name;
      const isSize = file.size / 1024 / 1024 < 60;
      if (!isSize) {
        this.$message.error("上传音频不能超过 60MB!");
        return;
      }

      let that = this;
      getRadioCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.radioLabForm.audio = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getRadioCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.radioLabForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击立即添加电台小节
    addRadioLabNow() {
      this.radioLabForm.radio_id = this.radioLabId;
      if (this.radioLabForm.title == "") {
        this.$message.error("请填写标题");
        return;
      } else if (this.radioLabForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.radioLabForm.audio == "") {
        this.$message.error("请上传音频");
        return;
      } else if (this.radioLabForm.subtitle == "") {
        this.$message.error("请输入字幕");
        return;
      }
      RadioLibAdd(this.radioLabForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加电台小节成功");
        this.radioLabDialog = false;
        this.getRadioLibList(this.radioLabId);
      });
    },

    // 点击修改电台小节图标
    editRadioLab(row) {
      this.isRadioLab = "edit";
      this.radioLabTitle = "编辑电台小节";
      this.radioLabDialog = true;
      this.radioLabForm = JSON.parse(JSON.stringify(row));
      this.audioSrc = this.radioLabForm.audio_url;
      this.thumbSrc = this.radioLabForm.thumb_url;
    },

    // 点击立即修改电台小节
    editRadioLabNow() {
      if (
        this.radioLabForm.radio_id == "" ||
        this.radioLabForm.radio_id == null
      ) {
        this.$message.error("请选择电台分类");
        return;
      } else if (this.radioLabForm.title == "") {
        this.$message.error("请填写标题");
        return;
      } else if (this.radioLabForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.radioLabForm.audio == "") {
        this.$message.error("请上传音频");
        return;
      } else if (this.radioLabForm.subtitle == "") {
        this.$message.error("请输入字幕");
        return;
      }
      let data = {
        radio_id: this.radioLabForm.radio_id,
        lib_id: this.radioLabForm.id,
        title: this.radioLabForm.title,
        subtitle: this.radioLabForm.subtitle,
        thumb: this.radioLabForm.thumb,
        audio: this.radioLabForm.audio,
      };
      RadioLibEdit(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改电台小节成功");
        this.radioLabDialog = false;
        this.getRadioLibList(this.radioLabId);
      });
    },

    // 添加电台小节弹框关闭
    radioLabClosed() {
      this.radioLabForm = {
        radio_id: null,
        thumb: "",
        title: "",
        audio: "",
        subtitle: "",
      };
      this.File_name = "";
      this.progress = 0;
      this.audioSrc = "";
      this.thumbSrc = "";
      if (this.$refs.audio !== undefined) {
        this.$refs.audio.pause();
      }
    },

    // 删除电台小节
    async removeRadioLab(id, target) {
      const result = await this.$confirm("是否要删除该电台?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      RadioLibDel({ lib_id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除电台成功");
        if (target == "lab") {
          this.getRadioLibList(this.radioLabId);
        } else {
          this.getRadioLibAuditList();
        }
      });
    },

    // 电台小节上架或下架
    editState(id, target) {
      if (target === 0) {
        RadioLibEnable({
          lib_id: id,
          enable: target,
        }).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.warning("下架成功");
          this.getRadioLibList(this.radioLabId);
        });
      } else {
        RadioLibEnable({
          lib_id: id,
          enable: target,
        }).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("上架成功");
          this.getRadioLibList(this.radioLabId);
        });
      }
    },

    // 电台主页面切换
    handleRadioSizeChange(size) {
      this.queryData.limit = size;
      this.getRadioList();
    },
    handleRadioCurrentChange(page) {
      this.queryData.page = page;
      this.getRadioList();
    },

    handleSizeChange(size) {
      this.queryLabForm.limit = size;
      this.getRadioLibList(this.radioLabId);
    },
    handleCurrentChange(page) {
      this.queryLabForm.page = page;
      this.getRadioLibList(this.radioLabId);
    },

    // 日语APP电台小节待审核列表分页
    handleRadioLibAuditSizeChange(size) {
      this.radioLibAuditFrom.limit = size;
      this.getRadioLibAuditList();
    },
    handleRadioLibAuditCurrentChange(page) {
      this.radioLibAuditFrom.page = page;
      this.getRadioLibAuditList();
    },
  },
};
</script>

<style scoped lang='less'>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box {
  height: 750px;
}
.detail {
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img {
  flex: 2;
}
.detail-img img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one {
  flex: 4;
}
.detail .detail-two {
  flex: 5;
}
.detail .detail-three {
  flex: 3;
}
.detail p {
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs {
  border-bottom: 2px solid #38a28a;
}
.detail-box .tabs .el-button {
  width: 160px;
  height: 50px;
  border-radius: 0;
}
.detail-box .detail-content {
  height: 504px;
  background-color: #f0f0f0;
}
.jurisdiction {
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content {
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm {
  text-align: center;
  height: 100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button {
  width: 90px;
  height: 40px;
}
.desc {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.desc img {
  width: 160px;
  height: 92px;
}
.generate {
  float: right;
}
.file-name {
  /* display: inline-block; */
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.imgSelect img {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
.videoSelect video {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

.title_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
::v-deep .ql-editor {
  min-height: 400px;
}

.radio_thumb_img {
  width: 100%;
  height: 100%;
  img {
    width: 110px;
    height: 110px;
  }
}
.thumb_img {
  width: 100%;
  height: 100%;
  img {
    width: 180px;
    height: 110px;
  }
}

.user_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .user_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user_name {
    margin-left: 5px;
  }
  .user_role {
    color: red;
  }
}
</style>
